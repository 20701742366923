import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: "gkq76dpa",
  dataset: "production",
  apiVersion: "2022-02-01",
  useCdn: true,
  token:
    "skOF4bD2sWYEy22X8M6a6ceOHikZdd78bt908rWwmqdPLF5QrFZMoDWukfvvrIqZbCQllevYkgtGDVeTJFx0UEt5LoUGW0yh37BB20Z0cXsWDFRQsOEd9kWYtj21kbbMNznneYG7Zy7rBbw5JnZvRANzokzk2hXo6d6YlEcMiEcmSU5SPiYf",
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
